import React from 'react';

export default () => {

    return (
        <>
            <button className="navbar-toggler sidebar-mobile-main-toggle d-md-none" type="button">
                <i className="icon-paragraph-justify3" />
            </button>
        </>
    );
};