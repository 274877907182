import axios from "axios";

export const CREATE_MESSAGE_START = 'CREATE_MESSAGE_START';
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS';
export const CREATE_MESSAGE_ERROR = 'CREATE_MESSAGE_ERROR';
export const CREATE_MESSAGE_RESET = 'CREATE_MESSAGE_RESET';

export const FETCH_MESSAGES_START = 'FETCH_MESSAGES_START';
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'FETCH_MESSAGES_ERROR';

/**
 * Send message to host
 *
 * @param bookingId
 * @param data
 * @returns {Function}
 */
export function createMessage(bookingId, data) {
    return function (dispatch) {
        dispatch({ type: CREATE_MESSAGE_START });
        const request = axios.post(`/bookings/${bookingId}/send-message-to-host`, JSON.stringify(data));
        request.then((res) => {
            dispatch({ type: CREATE_MESSAGE_SUCCESS });

            dispatch(fetchMessages(bookingId));

        }).catch((e) => {
            dispatch({ type: CREATE_MESSAGE_ERROR });
        });
    }
}

/**
 * Fetch messages
 *
 * @param bookingId
 * @returns {Function}
 */
export function fetchMessages(bookingId) {
    return function (dispatch) {
        dispatch({ type: FETCH_MESSAGES_START });
        const request = axios.get(`/bookings/${bookingId}/messages`);
        request.then((res) => {
            dispatch({ payload: res.data, type: FETCH_MESSAGES_SUCCESS });
        }).catch((e) => {
            dispatch({ type: FETCH_MESSAGES_ERROR })
        });
    }
}