import React from 'react';
import Loading from "./common/Loading";
import i18next from "i18next";

export default ({currentBooking}) => {
    const {booking} = currentBooking;

    if(!booking){
        return <Loading />
    }

    const style = {
      width : '100%',
      minHeight : 700,
      border : 'none'
    };

    return (
        <section id={'online-check-in'}>
            <iframe style={style} src={booking.onlineCheckInUrl} title={i18next.t('Online Check-in')}/>
        </section>
    )
}