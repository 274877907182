import axios from "axios";

export const FETCH_DAILY_WEATHER_START = 'FETCH_DAILY_WEATHER_START';
export const FETCH_DAILY_WEATHER_SUCCESS = 'FETCH_DAILY_WEATHER_SUCCESS';
export const FETCH_DAILY_WEATHER_ERROR = 'FETCH_DAILY_WEATHER_ERROR';

export const FETCH_CURRENTLY_WEATHER_START = 'FETCH_CURRENTLY_WEATHER_START';
export const FETCH_CURRENTLY_WEATHER_SUCCESS = 'FETCH_CURRENTLY_WEATHER_SUCCESS';
export const FETCH_CURRENTLY_WEATHER_ERROR = 'FETCH_CURRENTLY_WEATHER_ERROR';


export function fetchDailyWeather(bookingId) {
    return function (dispatch) {
        dispatch({ type: FETCH_DAILY_WEATHER_START });
        const request = axios.get(`/bookings/${bookingId}/weather-daily`);
        request.then((res) => {
            dispatch({ payload: res.data, type: FETCH_DAILY_WEATHER_SUCCESS });
        }).catch((e) => {
            dispatch({ type: FETCH_DAILY_WEATHER_ERROR })
        });
    }
}

export function fetchCurrentlyWeather(bookingId) {
    return function (dispatch) {
        dispatch({ type: FETCH_CURRENTLY_WEATHER_START });
        const request = axios.get(`/bookings/${bookingId}/weather-currently`);
        request.then((res) => {
            dispatch({ payload: res.data, type: FETCH_CURRENTLY_WEATHER_SUCCESS });
        }).catch((e) => {
            dispatch({ type: FETCH_CURRENTLY_WEATHER_ERROR })
        });
    }
}