import React, { Component } from 'react';
import Loading from './common/Loading';

export default class extends Component {

    loadBookingTool(url, scriptUrl, baseUrl) {
        const script = document.createElement("script");
        script.src = scriptUrl;
        script.async = true;
        script.onload = () => {
            window.BookingToolIframe.initialize({ url, baseUrl, "target": "#booking-tool-container-allApartments" });
        };

        document.body.appendChild(script);
    }

    render() {
        const { booking } = this.props.currentBooking;

        if (!booking) {

            return <Loading />;
        }

        const { url, scriptUrl, baseUrl } = booking.bookingToolIframe;

        this.loadBookingTool(url, scriptUrl, baseUrl);

        return (
            <section id={'booking-tool'}>
                <div id="booking-tool-container-allApartments" />
            </section>
        );
    }
}