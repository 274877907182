import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';
import {
    createOrder,
    disableOrderButton,
    enableOrderButton,
    fetchPaymentMethods, hideCart, hideProductList, showCart, showProductList
} from "../../actions/CrossSellingAction";
import axios from "axios";
import { BOOKING_ID, PIN_CODE, session, TOKEN } from "../../utils/Session";
import { numberFormat } from "../../utils/Localization";
import { moveToSection } from "../../utils/Menu";

const STRIPE_CONNECT_PAYMENT_METHODS = [
    16, 32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384, 32768, 65536, 131072,
    262144, 524288, 1048576, 2097152, 4194304, 8388608, 16777216, 33554432
];

class CheckoutView extends Component {

    state = {
        showStripeCard: false,
        showStripeConnectElement: false,
        selectedPaymentMethodId: null,
        showBuyNowBtn : false,
        showResetPaymentMethodBtn : false
    };

    stripe = null;
    stripeCard = null;
    stripeError = '';

    stripeConnect = null;
    stripeConnectElement = null;
    stripeConnectElements = null;
    stripeConnectError = '';

    componentDidMount() {
        const bookingId = session.getItem(BOOKING_ID);
        this.props.dispatch(fetchPaymentMethods(bookingId));
        this.props.dispatch(disableOrderButton());
    }

    componentDidUpdate(prevProps) {

        if (this.props.paymentMethods.length && this.props.paymentMethods !== prevProps.paymentMethods) {

            const script = document.createElement("script");
            script.src = 'https://js.stripe.com/v3/';
            script.async = true;

            script.onload = () => {

                if (this.getPaymentMethod(2)) {
                    this.initializeStripe();
                }
            }

            document.body.appendChild(script);
        }
    }

    initializeStripe = () => {
        const stripePaymentMethod = this.getPaymentMethod(2);
        this.stripe = window.Stripe(stripePaymentMethod.publicKey);
        const elements = this.stripe.elements();
        this.stripeCard = elements.create('card');
        this.stripeCard.mount('#card-element');
        this.stripeCard.addEventListener('change', (event) => {
            if (event.error) {
                this.setState({ stripeError: event.error.message });
            } else {
                this.setState({ stripeError: '' });
            }
        });
    };

    handlePaymentMethodChange = (event) => {
        const selectedPaymentMethodId = parseInt(event.target.value);
        this.setState({ selectedPaymentMethodId });
        this.props.dispatch(enableOrderButton());
        this.props.dispatch(hideCart());
        this.props.dispatch(hideProductList());
        this.setState({ showStripeCard: selectedPaymentMethodId === 2 });

        if (STRIPE_CONNECT_PAYMENT_METHODS.includes(selectedPaymentMethodId)) {

            this.initializeStripeConnect(selectedPaymentMethodId);
        } else {
            this.setState({ showBuyNowBtn: true });
            this.setState({ showResetPaymentMethodBtn: true });
        }

        moveToSection('additional-items');
    };

    resetPaymentMethod = (event) => {
        this.setState({ selectedPaymentMethodId : null });
        this.props.dispatch(showCart());
        this.props.dispatch(showProductList());
        this.setState({ showBuyNowBtn: false });
        this.setState({ showResetPaymentMethodBtn: false });
        this.setState({ stripeConnectError: '' });

        if(this.stripeConnectElement) {
            this.stripeConnectElement.destroy();
            this.stripeConnectElement = null;
        }
    }

    getPaymentMethod = (id) => {
        const { paymentMethods } = this.props;

        for (let paymentMethod of paymentMethods) {
            if (paymentMethod.id === id) {

                return paymentMethod;
            }
        }

        return null;
    };

    handleOrder = (event) => {
        this.props.dispatch(disableOrderButton());

        if (this.state.selectedPaymentMethodId === 2) {
            this.handleStripe();
            return;
        }

        if (STRIPE_CONNECT_PAYMENT_METHODS.includes(this.state.selectedPaymentMethodId)) {
            this.handleStripeConnect();
            return;
        }

        this.props.dispatch(createOrder(this.props.cart.items, this.state.selectedPaymentMethodId));
    };

    handleStripe = () => {
        this.stripe.createPaymentMethod('card', this.stripeCard).then((result) => {
            if (result.error) {
                this.setState({ stripeError: result.error.message });
                this.props.dispatch(enableOrderButton());
                return;
            }
            const bookingId = session.getItem(BOOKING_ID);
            const totalPrice = this.props.cart.items.reduce((total, item) => total + (item.product.price * item.quantity), 0);
            const description = this.props.cart.items.map((item, _) => item.product.name + ' x ' + item.quantity);
            const request = axios.post(`/bookings/${bookingId}/payment/create-stripe-intent`, JSON.stringify({
                'paymentMethodId': result.paymentMethod.id,
                'amount': parseFloat(totalPrice),
                'description': description.join(', '),
            }));
            request.then((response) => {
                this.handleStripeResponse(response.data);
            })
        });
    };

    handleStripeResponse(response) {
        if (response.error) {
            this.setState({ stripeError: response.error.message });
            this.props.dispatch(enableOrderButton());
        } else if (response.requires_action) {
            // Use Stripe.js to handle required card action
            this.handleStripeAction(response);
        } else {

            if (response.payment_intent_id) {
                const addtionalParams = { stripeToken: response.payment_intent_id };
                this.props.dispatch(createOrder(this.props.cart.items, this.state.selectedPaymentMethodId, addtionalParams))
            }
        }
    }

    handleStripeAction(response) {
        this.stripe.handleCardAction(
            response.payment_intent_client_secret
        ).then((result) => {

            if (result.error) {
                this.setState({ stripeError: result.error.message });
            } else {
                const bookingId = session.getItem(BOOKING_ID);
                const request = axios.post(`/bookings/${bookingId}/payment/confirm-stripe-intent`, JSON.stringify({
                    'paymentIntentId': result.paymentIntent.id,
                }));

                request.then((respones) => {
                    this.handleStripeResponse(respones.data);
                })

            }
        });
    }

    initializeStripeConnect = (selectedPaymentMethodId) => {
        const stripeConnectPaymentMethod = this.getPaymentMethod(selectedPaymentMethodId);
        const bookingId = session.getItem(BOOKING_ID);
        const totalPrice = this.props.cart.items.reduce((total, item) => total + (item.product.price * item.quantity), 0);
        const description = this.props.cart.items.map((item, _) => item.product.name + ' x ' + item.quantity);
        const request = axios.post(`/bookings/${bookingId}/payment/create-stripe-connect-intent`, JSON.stringify({
            'paymentMethodId': stripeConnectPaymentMethod.id,
            'amount': parseFloat(totalPrice),
            'description': description.join(', '),
        }));
        request.then((response) => {

            const data = response.data;
            if(data.success !== true) {

                this.setState({ stripeConnectError: data.error.message });
                this.setState({ showStripeConnectElement: true });
                this.setState({ showResetPaymentMethodBtn: true });
                return;
            } else {
                this.setState({ stripeConnectError: '' });
            }
            //this.handleStripeConnectResponse(response.data);
            this.stripeConnect = window.Stripe(stripeConnectPaymentMethod.publicKey, {
                stripeAccount: stripeConnectPaymentMethod.stripeUserId
            });
            const elements = this.stripeConnect.elements({
                clientSecret: data.client_secret
            });
            this.stripeConnectElements = elements;
            this.stripeConnectElement = elements.create('payment');
            this.stripeConnectElement.mount('#payment-element');
            this.setState({ showStripeConnectElement: true });
            this.stripeConnectElement.on('ready', () => {
                this.setState({ showBuyNowBtn: true });
                this.setState({ showResetPaymentMethodBtn: true });
            });
        });
    };

    handleStripeConnect = async () => {
        const { error } = await this.stripeConnect.confirmPayment({
            elements : this.stripeConnectElements,
            confirmParams: {
                return_url: this.prepareStripeConnectReturnUrl(this.props.cart.items),
            },
        });

        if (error) {

            this.setState({ stripeConnectError: error.message });
            this.props.dispatch(enableOrderButton());
        }
    };

    prepareStripeConnectReturnUrl = (cartItems) => {
        // only add necessary data as it will be embedded in url parameter
        cartItems = cartItems.map((item) => {
            return { product: { id: item.product.id }, quantity: item.quantity }
        });

        let returnUrl = window.location.origin + '/stripe-connect-payment-confirmation?';
        returnUrl += 'bookingId=' + session.getItem(BOOKING_ID);
        returnUrl += '&token=' + session.getItem(TOKEN);
        returnUrl += '&pinCode=' + session.getItem(PIN_CODE);
        returnUrl += '&paymentMethodId=' + this.state.selectedPaymentMethodId;
        returnUrl += '&cartItems=' + encodeURI(JSON.stringify(cartItems));

        return returnUrl;
    }

    getTotalAmount = () => {
        const totalAmount = this.props.cart.items.reduce((total, item) => total + (item.product.price * item.quantity), 0);
        return parseFloat(totalAmount);
    }

    getCurrency = () => {
        const items = this.props.cart.items;
        return items.length ? items[0]['product']['currency']['symbol'] : '';
    }

    render() {

        const { paymentMethods } = this.props;

        const { successMessage, errorMessage, disableOrderButton } = this.props.order;

        return (
            <>
                <section id={'checkout'}>
                    {!this.state.selectedPaymentMethodId && paymentMethods.map((item, index) => {

                        return (
                            <div className={'form-check'} key={index}>
                                <input
                                    type={'radio'}
                                    className={'form-check-input'}
                                    name={'paymentMethod'}
                                    value={item.id}
                                    id={`payment-method-${item.id}`}
                                    onChange={this.handlePaymentMethodChange}
                                />
                                <label className={'form-check-label'} htmlFor={`payment-method-${item.id}`}>{item.label}</label>
                            </div>
                        );
                    })}

                    {this.state.selectedPaymentMethodId &&
                      <div className="row">
                          <table className="table table-borderless col-md-6">
                              <tbody>
                                  <tr>
                                      <td>{i18next.t('Payment method')} </td>
                                      <td>{this.getPaymentMethod(this.state.selectedPaymentMethodId).label}</td>
                                  </tr>
                                  <tr>
                                      <td>{i18next.t('Total amount')}</td>
                                      <td>{this.getCurrency()} {numberFormat.format(this.getTotalAmount())}</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                    }
                    {
                        <div className="form-group" style={!this.state.showStripeCard ? { 'display': 'none' } : {}}>
                            <input type="hidden" name="stripeToken" id="stripeToken" />
                            <div id="card-element" />
                            <div id="card-errors" className="card-errors help-block text-danger" role="alert">{this.state.stripeError}</div>
                        </div>
                    }

                    {
                        <div className="form-group mt-4" style={!this.state.showStripeConnectElement ? { 'display': 'none' } : {}}>
                            <div id="payment-element" />
                            <div id="payment-element-errors" className="payment-element-errors help-block text-danger" role="alert">{this.state.stripeConnectError}</div>
                        </div>
                    }

                    {successMessage && <div className={'alert alert-success'}>{successMessage}</div>}
                    {errorMessage && <div className={'alert alert-danger'}>{errorMessage}</div>}
                    <div className={'d-flex align-items-end flex-column'}>
                        <div>
                            <button style={!this.state.showResetPaymentMethodBtn ? { 'display': 'none' } : {}}
                                    className="btn btn-light mt-4 mr-0"
                                    type={'button'}
                                    onClick={this.resetPaymentMethod}
                            >{i18next.t('Back')}
                            </button>
                            <button style={!this.state.showBuyNowBtn ? { 'display': 'none' } : {}}
                                    className={'btn btn-primary mt-4'}
                                    type={'button'}
                                    onClick={this.handleOrder}
                                    disabled={disableOrderButton}
                            >{i18next.t('Buy now')}
                            </button>
                        </div>

                    </div>

                </section>
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        cart: state.cart,
        paymentMethods: state.paymentMethods,
        order: state.order
    };
};

export default connect(mapStateToProps)(CheckoutView);