import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { parse } from 'query-string';
import axios from 'axios';
import './i18n';
import AppContainer from './containers/AppContainer';
import store from './store';
import * as serviceWorker from './serviceWorker';
import { session, TOKEN, BOOKING_ID, PIN_CODE, VALID_UNTIL } from "./utils/Session";
import moment from "moment";

const params = parse(document.location.search);
const token = params.t || params.token;
const bookingId = params.b || params.bookingId;

if (token && bookingId) {

    // url is changed.
    if(session.getItem(TOKEN) && session.getItem(TOKEN) !== token.toString()) {
        session.reset();
    }

    session.setItem(TOKEN, token.toString());
    session.setItem(BOOKING_ID, bookingId.toString());
}

if (session.isAuthenticated() && moment().unix() > session.getItem(VALID_UNTIL)) {
    session.reset();
}

axios.defaults.params = { 'token': session.getItem(TOKEN) };

if(session.getItem(PIN_CODE)) {
    axios.defaults.params.pinCode = session.getItem(PIN_CODE);
}

axios.interceptors.request.use(function (config) {
    config.baseURL = process.env.REACT_APP_API_BASE_URL;
    return config;
});


ReactDOM.render(
    <Provider store={store}>
        <AppContainer />
    </Provider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
