import { connect } from 'react-redux';
import BookingToolView from '../views/BookingToolView';

const mapStateToProps = (state) => {
    return {
        currentBooking: state.currentBooking,
    };
};

export default connect(mapStateToProps, {})(BookingToolView);
