import {
    CHECK_BOOKING_WITHOUT_PIN_CODE_START,
    CHECK_BOOKING_WITHOUT_PIN_CODE_ERROR, CHECK_BOOKING_WITHOUT_PIN_CODE_SUCCESS,
} from '../actions/BookingAction';

const initialState = {
    booking: null,
    errorMessage: '',
    loading: false,
    done : false
};

export default function checkBookingWithoutPinCodeReducer(state = initialState, action) {
    const payload = action.payload;
    switch (action.type) {
        case CHECK_BOOKING_WITHOUT_PIN_CODE_START: {
            return { ...initialState, loading: true };
        }
        case CHECK_BOOKING_WITHOUT_PIN_CODE_SUCCESS: {
            return { ...initialState, booking: payload, done: true }
        }
        case CHECK_BOOKING_WITHOUT_PIN_CODE_ERROR: {
            let errorMessage = 'Ooops, something went wrong';
            if (payload.status === 422) {
                errorMessage = payload.detail
            }

            return { ...initialState, errorMessage, done: true }
        }
        default:
            return state;
    }
}