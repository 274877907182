import {
    FETCH_CURRENTLY_WEATHER_START, FETCH_CURRENTLY_WEATHER_SUCCESS, FETCH_CURRENTLY_WEATHER_ERROR,
} from '../../actions/WeatherAction';

const initialState = { data: null, loading: false, errorMessage: '' }

export default function weatherCurrentlyReducer(state = initialState, action) {
    const payload = action.payload;
    switch (action.type) {
        case FETCH_CURRENTLY_WEATHER_START: {
            return { ...initialState, loading: true};
        }
        case FETCH_CURRENTLY_WEATHER_SUCCESS: {
            return { ...initialState,  data: payload }
        }
        case FETCH_CURRENTLY_WEATHER_ERROR: {
            return { ...initialState,errorMessage: 'Ooops, something went wrong' }
        }
        default:
            return state;
    }
}