import { combineReducers } from 'redux';
import currentBookingReducer from '../reducers/CurrentBookingReducer';
import contentListReducer from '../reducers/ContentListReducer';
import weatherCurrentlyReducer from "./weather/WeatherCurrentlyReducer";
import weatherDailyReducer from "./weather/WeatherDailyReducer";
import CreateReviewReducer from "./CreateReviewReducer";
import TourListReducer from "./TourListReducer";
import CreateMessageReducer from "./message/CreateMessageReducer";
import messageListReducer from "./message/MessageListReducer";
import productListReducer from "./cross-selling/ProductListReducer";
import cartReducer from "./cross-selling/CartReducer";
import paymentMethodsReducer from "./cross-selling/PaymentMethodsReducer";
import orderReducer from "./cross-selling/OrderReducer";
import checkBookingWithoutPinCodeReducer from "./CheckBookingWithoutPinCodeReducer";

const rootReducer = combineReducers({
    currentBooking: currentBookingReducer,
    checkBookingWithoutPinCode: checkBookingWithoutPinCodeReducer,
    contentList: contentListReducer,
    weatherCurrently : weatherCurrentlyReducer,
    weatherDaily : weatherDailyReducer,
    createReview : CreateReviewReducer,
    tourList : TourListReducer,
    createMessage : CreateMessageReducer,
    messageList : messageListReducer,
    productList : productListReducer,
    cart : cartReducer,
    paymentMethods : paymentMethodsReducer,
    order : orderReducer
});

export default rootReducer;
