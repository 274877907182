import React, { Component } from 'react';
import HeaderContainer from '../containers/HeaderContainer';
import LeftSideBarContainer from '../containers/LeftSideBarContainer';
import HomeContainer from '../containers/HomeContainer';
import Footer from '../views/common/Footer';
import { BrowserRouter as Router, Route } from 'react-router-dom'

import '../assets/import-jquery';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../assets/limitless/js/app';
import '../assets/limitless/css/bootstrap.css';
import '../assets/App.scss';
import '../assets/plugins/jquery.scrollify';
import '../assets/plugins/lightslider/dist/js/lightslider';
import '../assets/plugins/lightslider/dist/css/lightslider.css';

import BookNextContainer from "../containers/BookingToolContainer";
import OnlineCheckInContainer from "../containers/OnlineCheckInContainer";
import ReviewContainer from "../containers/ReviewContainer";
import { fetchProducts } from "../actions/CrossSellingAction";
import StripeConnectPaymentConfirmationView from "./cross-selling/StripeConnectPaymentConfirmationView";
import { session, BOOKING_ID, TOKEN } from "../utils/Session";
import AuthView from "./AuthView";
import i18next from 'i18next';


class AppView extends Component {
    componentDidMount() {
        if(session.isAuthenticated()) {
            this.props.fetchBooking(session.getItem(BOOKING_ID));
            this.props.dispatch(fetchProducts(session.getItem(BOOKING_ID)));
        }
    }

    render() {

        if(!session.getItem(TOKEN)) {

            return <div>{i18next.t('Invalid link !!!')}</div>
        }

        return (
            <div className="container mt-5">
                <Router>
                    <div>
                        {!session.isAuthenticated() && <AuthView />}
                        {
                            session.isAuthenticated() && <>
                                <HeaderContainer />
                                <div>
                                    <LeftSideBarContainer />
                                    <Route exact path="/" component={HomeContainer} />
                                    <Route exact path="/stripe-connect-payment-confirmation" component={StripeConnectPaymentConfirmationView} />
                                    <Route exact path="/book-next-stay" component={BookNextContainer} />
                                    <Route exact path="/online-check-in" component={OnlineCheckInContainer} />
                                    <Route exact path="/review" component={ReviewContainer} />
                                </div>
                                <Footer />
                            </>
                        }
                    </div>
                </Router>
            </div>
        );
    }
}

export default AppView;
