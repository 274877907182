import React, { Component } from 'react';
import Loading from './common/Loading';
import moment from "moment";
import { BOOKING_ID, session } from "../utils/Session";

export default class extends Component {

    componentDidMount() {
        this.props.fetchCurrentlyWeather(session.getItem(BOOKING_ID));
        //this.props.fetchDailyWeather(sessionStorage.getItem('bookingId'));
    }

    render() {
        const { weatherCurrently } = this.props;

        if (weatherCurrently.loading) {
            return <Loading />;
        }

        if (!weatherCurrently.data || !Object.keys(weatherCurrently.data).length) {
            return '';
        }

        const { summary, icon, temperature, time } = weatherCurrently.data;
        const day = moment(time * 1000);

        const style = this.props.booking.settings.weatherBackgroundImage ? { 'backgroundImage': `url(${this.props.booking.settings.weatherBackgroundImage})` } : {};
        return (
            <>
                <section id={'weather'} style={style}>
                    <div className={'row'}>
                        <div className={'col-12'} style={{'textAlign' : 'right'}}>
                            <div className={'icon'} title={summary}>
                                <img src={`/images/DarkSky/${icon}.svg`} alt={''} />
                            </div>

                            <div className={'current-day-temp'}>
                                {window.navigator.language === 'en-US' ? `${Math.round((temperature * 9 / 5) + 32)}°F` : `${Math.round(temperature)}°C`}
                            </div>
                            <div className={'day'}>{day.format('dddd')} {day.format('HH:mm')}</div>
                            <div className={'current-day-description'}>{summary}</div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}