import axios from "axios";
import i18next from "i18next";
import { IS_AUTHENTICATED, session } from "../utils/Session";

export const FETCH_BOOKINGS_START = 'FETCH_BOOKINGS_START';
export const FETCH_BOOKINGS_SUCCESS = 'FETCH_BOOKINGS_SUCCESS';
export const FETCH_BOOKINGS_ERROR = 'FETCH_BOOKINGS_ERROR';

//Fetch BOOKING
export const FETCH_BOOKING_START = 'FETCH_BOOKING_START';
export const FETCH_BOOKING_SUCCESS = 'FETCH_BOOKING_SUCCESS';
export const FETCH_BOOKING_ERROR = 'FETCH_BOOKING_ERROR';

export const CHECK_BOOKING_WITHOUT_PIN_CODE_START = 'CHECK_BOOKING_WITHOUT_PIN_CODE_START';
export const CHECK_BOOKING_WITHOUT_PIN_CODE_SUCCESS = 'CHECK_BOOKING_WITHOUT_PIN_CODE_SUCCESS';
export const CHECK_BOOKING_WITHOUT_PIN_CODE_ERROR = 'CHECK_BOOKING_WITHOUT_PIN_CODE_ERROR';

export function fetchBookings(params = {}) {
    return function (dispatch) {
        dispatch({ type: FETCH_BOOKINGS_START });
        const request = axios.get(`/bookings`, { params });
        request.then((res) => {
            const { bookings } = res.data;
            const payload = {
                bookings: bookings ? bookings : [],
            };
            dispatch({ payload, type: FETCH_BOOKINGS_SUCCESS });
        }).catch((e) => {
            dispatch({ type: FETCH_BOOKINGS_ERROR })
        });
    }
}

export function fetchBooking(id) {
    return function (dispatch) {
        dispatch({ type: FETCH_BOOKING_START });
        const request = axios.get(`/bookings/${id}`);
        request.then((res) => {
            if (res.data.language) {
                i18next.changeLanguage(res.data.language);
            }

            dispatch({ payload: res.data, type: FETCH_BOOKING_SUCCESS });
        }).catch((e) => {
            if (e.response) {
                const payload = e.response.data;
                payload['status'] = e.response.status;
                dispatch({ payload, type: FETCH_BOOKING_ERROR });

                // in case user already have session, it is invalid due to host pincode auth settings.
                if (e.response.status === 422 && session.isAuthenticated()) {
                    session.setItem(IS_AUTHENTICATED, 0);
                    window.location.reload();
                }
            }
        });
    }
}

export function checkBookingWithoutPinCode(id) {
    return function (dispatch) {
        dispatch({ type: CHECK_BOOKING_WITHOUT_PIN_CODE_START });
        const request = axios.get(`/bookings/${id}`);
        request.then((res) => {
            if (res.data.language) {
                i18next.changeLanguage(res.data.language);
            }

            dispatch({ payload: res.data, type: CHECK_BOOKING_WITHOUT_PIN_CODE_SUCCESS });
        }).catch((e) => {
            if (e.response) {
                const payload = e.response.data;
                payload['status'] = e.response.status;
                dispatch({ payload, type: CHECK_BOOKING_WITHOUT_PIN_CODE_ERROR });
            }
        });
    }
}