import {
    FETCH_TOURS_START, FETCH_TOURS_SUCCESS, FETCH_TOURS_ERROR,
} from '../actions/TourAction';

const initialState = {
    tours: [],
    errorMessage: '',
    loading: false,
};

export default function tourListReducer(state = initialState, action) {
    const payload = action.payload;
    switch (action.type) {
        case FETCH_TOURS_START: {
            return { ...initialState, loading: true };
        }
        case FETCH_TOURS_SUCCESS: {
            return { ...initialState, tours: payload }
        }
        case FETCH_TOURS_ERROR: {
            return { ...initialState, errorMessage: 'Ooops, something went wrong' }
        }
        default:
            return state;
    }
}