import { connect } from 'react-redux';
import HomeView from '../views/HomeView';
const mapStateToProps = (state) => {
    return {
        booking : state.currentBooking.booking,
        contents : state.contentList.contents,
        tours : state.tourList.tours,
        products : state.productList.products
    };
};
export default connect(mapStateToProps)(HomeView);
