import i18n from 'i18next';
//import Backend from 'i18next-xhr-backend';
//import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationDE     from './translations/de.json';
import translationEL     from './translations/el.json';
import translationEN     from './translations/en.json';
import translationES     from './translations/es.json';
import translationFR     from './translations/fr.json';
import translationIT     from './translations/it.json';
import translationNL     from './translations/nl.json';
import translationPT     from './translations/pt.json';
import translationRU     from './translations/ru.json';

// the translations
const resources = {
    de: {
        translation: translationDE
    },
    el: {
        translation: translationEL
    },
    en: {
        translation: translationEN
    },
    es: {
        translation: translationES
    },
    fr: {
        translation: translationFR
    },
    it: {
        translation: translationIT
    },
    nl: {
        translation: translationNL
    },
    pt: {
        translation: translationPT
    },
    ru: {
        translation: translationRU
    },
};


i18n
// load translation using xhr -> see /public/locales
// learn more: https://github.com/i18next/i18next-xhr-backend
    //.use(Backend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        fallbackLng: 'en',
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            prefix : '[',
            suffix : ']'
        },
        backend : {
            loadPath : '/translations/[lng].json',
            addPath: '/translations/add/[lng]/',
        },
    });

export default i18n;