import {
    FETCH_BOOKING_START, FETCH_BOOKING_SUCCESS, FETCH_BOOKING_ERROR,
} from '../actions/BookingAction';

const initialState = {
    booking: null,
    errorMessage: '',
    loading: false,
};

export default function currentBookingReducer(state = initialState, action) {
    const payload = action.payload;
    switch (action.type) {
        case FETCH_BOOKING_START: {
            return { ...initialState, loading: true };
        }
        case FETCH_BOOKING_SUCCESS: {
            return { ...initialState, booking: payload }
        }
        case FETCH_BOOKING_ERROR: {
            let errorMessage = 'Ooops, something went wrong';
            if (payload.status === 422) {
                errorMessage = payload.detail
            }

            return { ...initialState, errorMessage }
        }
        default:
            return state;
    }
}