import React, { Component } from 'react';
import i18next from "i18next";
import Error from "../common/Error";
import { connect } from "react-redux";
import { createMessage } from "../../actions/MessageAction";

window.i18next = i18next;

export class CreateMessageView extends Component {

    constructor(props) {
        super(props);

        this.formData = {};
        this.formRef = React.createRef();

    }

    handleChange = (event) => {
        this.formData[event.target.getAttribute('name')] = event.target.value;
    };

    handleSubmit = (event) => {
        event.preventDefault();

        if (!this.formData.messageBody) {
            return;
        }

        this.props.dispatch(createMessage(this.props.booking.id, this.formData));

    };

    render() {
        const { booking } = this.props;

        if (!booking) {
            return '';
        }

        const { loading, successMessage, errorMessage } = this.props.createMessage;

        if (successMessage) {
            this.formRef.current.reset();
            this.formData = {};
        }

        return (
            <>
                <form onSubmit={this.handleSubmit} ref={this.formRef}>
                    <div className={'form-group row'}>
                        <div className="col-12">
                            <textarea
                                rows="6"
                                cols="3"
                                className="form-control"
                                placeholder={i18next.t('Enter your message here')}
                                name="messageBody"
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className={'form-group row mt-1'}>
                        <div className="col-form-label col-12">
                            <button type={'submit'} className={'btn btn-primary'} style={{'float' : 'right'}}>{i18next.t('Send')}</button>
                        </div>
                    </div>
                    {loading &&
                    <div>{i18next.t('Working')}</div>
                    }
                    {errorMessage &&
                    <Error message={errorMessage} />
                    }
                </form>
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        booking: state.currentBooking.booking,
        createMessage: state.createMessage,
    };
};

export default connect(mapStateToProps)(CreateMessageView);