import {
    FETCH_PAYMENT_METHODS_SUCCESS, FETCH_PAYMENT_METHODS_ERROR
} from '../../actions/CrossSellingAction';

export default function paymentMethodsReducer(state = [], action) {
    const payload = action.payload;
    switch (action.type) {
        case FETCH_PAYMENT_METHODS_SUCCESS: {
            return payload;
        }
        case FETCH_PAYMENT_METHODS_ERROR: {
            return [];
        }
        default:
            return state;
    }
}