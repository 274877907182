import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from "../common/Loading";
import { addToCart } from "../../actions/CrossSellingAction";
import { numberFormat } from "../../utils/Localization";
import i18next from "i18next";

class ProductListView extends Component {

    colors = [
        '#ffb3ba',
        '#ffdfba',
        '#ffffba',
        '#baffc9',
        '#bae1ff',
        '#ffd4e5',
        '#d4ffea',
        '#eecbff',
        '#feffa3',
        '#dbdcff',
        '#ffdef2',
        '#f2e2ff',
        '#e2eeff',
        '#ddfffc',
        '#ffffe3'
    ];

    /**
     * Add product to cart
     * @param product
     */
    addToCard = (product) => {
        this.props.dispatch(addToCart(product, 1));
    };
    /**
     * Check if product can be added to cart depending on product quantity limit
     *
     * @param product
     * @returns {boolean}
     */
    canOrder = (product) => {
        const cartItems = this.props.cart.items;
        if(!product.freeQuantity){
            return  false;
        }
        for (let i = 0; i < cartItems.length; i++) {
            const item = cartItems[i];
            if (product.id === item.product.id) {
                if (item.quantity >= product.freeQuantity) {
                    return false;
                }
            }
        }

        return true;
    };

    render() {
        const { products, loading, showProductList } = this.props.productList;

        if (loading) {
            return <Loading />;
        }

        if (!products.length) {
            return '';
        }

        return (
            <>
                <div className={'products'} style={!showProductList ? { 'display': 'none' } : {}}>
                    <div className="row product-list">
                        {products.map((product, index) => {
                            return (
                                <div key={index} className={'col-xs-6 col-sm-6'}>
                                    <div className={'product-list-item d-flex justify-content-between'}>

                                        <div className={'name'}>{product.name}</div>
                                        <div>
                                            <div className={'price'}>{product.currency.symbol} {numberFormat.format(product.price)}</div>
                                            <button className={'add-to-cart-btn btn'} disabled={!this.canOrder(product)} onClick={(event) => {
                                                event.preventDefault();
                                                this.addToCard(product);
                                            }
                                            }>
                                                <img src={'/images/icons/add-cart-item.svg'} alt={i18next.t('Add to cart')} />
                                            </button>
                                        </div>


                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        productList: state.productList,
        cart: state.cart,
    };
};

export default connect(mapStateToProps)(ProductListView);