import { CART_ADD_ITEM, CART_HIDE, CART_REMOVE_ITEM, CART_RESET, CART_SHOW } from "../../actions/CrossSellingAction";
import cloneDeep from "lodash/cloneDeep";
const initialState = {
    items: [],
    showCart : true
};

export default function cartReducer(state = initialState, action) {
    const payload = action.payload;
    const newState = cloneDeep(state);
    switch (action.type) {
        case CART_ADD_ITEM: {
            const cartItems = newState.items;
            const { product, quantity } = payload;
            const productExistsInCart = cartItems.some((item) => item.product.id === product.id);

            if (productExistsInCart) {
                cartItems.forEach((item) => {
                    if (item.product.id === product.id) {
                        item.quantity = item.quantity + quantity;
                    }
                });
            } else {
                cartItems.push({ ...payload });
            }
            newState.items = cartItems;
            return newState;
        }
        case CART_REMOVE_ITEM: {
            newState.items = newState.items.filter((item) => item.product.id !== payload.product.id);

            return newState;
        }
        case CART_RESET: {
            return { ...initialState }
        }
        case CART_SHOW: {
            newState.showCart = true;
            return newState;
        }
        case CART_HIDE: {
            newState.showCart = false;
            return newState;
        }
        default:
            return newState;
    }
}

