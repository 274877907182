import {
    FETCH_CONTENTS_START, FETCH_CONTENTS_SUCCESS, FETCH_CONTENTS_ERROR,
} from '../actions/ContentAction';

const initialState = {
    contents: [],
    errorMessage: '',
    loading: false,
};

export default function contentListReducer(state = initialState, action) {
    const payload = action.payload;
    switch (action.type) {
        case FETCH_CONTENTS_START: {
            return { ...initialState, loading: true };
        }
        case FETCH_CONTENTS_SUCCESS: {
            return { ...initialState, contents: payload }
        }
        case FETCH_CONTENTS_ERROR: {
            return { ...initialState, errorMessage: 'Ooops, something went wrong' }
        }
        default:
            return state;
    }
}