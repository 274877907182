import React, { Component } from 'react';
import i18next from "i18next";

export default class extends Component {

    mapRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = {showMap: false};
    }

    componentDidMount() {

        if (this.props.booking.apartment.address.displayMap) {

            this.loadGoogleMap();
        }
    }

    loadGoogleMap = (event) => {
        this.setState({showMap: true});
        const googleMapScript = document.createElement('script');
        googleMapScript.src = process.env.REACT_APP_GOOGLE_MAP_API_URL;

        window.document.body.appendChild(googleMapScript);

        googleMapScript.addEventListener('load', () => {
            const { latitude, longitude } = this.props.booking.apartment.address;

            const map  = new window.google.maps.Map(this.mapRef.current, {
                zoom: 16,
                center: {
                    lat: latitude,
                    lng: longitude,
                },
                //disableDefaultUI: true,
            });

            new window.google.maps.Marker({
                position: {
                    lat: latitude,
                    lng: longitude,
                },
                map: map,
                title: this.props.booking.apartment.name
            });

        })
    }

    render() {

        return (
            <>
                {!this.props.booking.apartment.address.displayMap && !this.state.showMap &&
                    <section className={'bluredMap'}>
                        <svg className="bluredSvg" fill="none" height="100%" illustration="map" viewBox="0 0 720 217"
                             xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#map_svg__a)">
                                <path d="M0 0h720v330H0V0z" fill="#fff"></path>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M226.821 29.777 209.34 55.603a4.835 4.835 0 0 0-.825 2.487l-3.277 72.657c-.016.339.022.679.113 1.007l9.383 33.692a3.219 3.219 0 0 0 4.174 2.169 3.223 3.223 0 0 0 1.419-1.001l65.404-80.36a3.217 3.217 0 0 0-.243-4.327l-53.752-52.643a3.225 3.225 0 0 0-2.573-.903 3.211 3.211 0 0 0-2.342 1.396zM239.54 13.07 248.298.31a3.22 3.22 0 0 1 3.751-1.202c.444.162.848.42 1.181.755l56.435 56.78a3.216 3.216 0 0 1 .037 4.499l-8.218 8.537a3.215 3.215 0 0 1-4.496.134l-56.977-52.56a3.216 3.216 0 0 1-.471-4.185zm-4.739-23.658-17.592 29.88a3.213 3.213 0 0 1-3.617 1.47 3.215 3.215 0 0 1-2.37-3.103v-29.88a3.217 3.217 0 0 1 3.216-3.216h17.592a3.215 3.215 0 0 1 2.771 4.85zm-36.592 1.63-1.764 16.116a3.214 3.214 0 0 1-3.199 2.867H169.1a3.217 3.217 0 0 1-3.212-3.38l.823-16.12a3.218 3.218 0 0 1 3.212-3.05h25.087a3.218 3.218 0 0 1 3.2 3.566h-.001zm-3.834 32.06-1.335 17.28a3.213 3.213 0 0 1-3.206 2.969h-22.82a3.214 3.214 0 0 1-3.029-2.13 3.216 3.216 0 0 1-.183-1.294l1.116-17.28a3.218 3.218 0 0 1 3.209-3.01h23.041a3.219 3.219 0 0 1 3.207 3.464zm-2.867 32.1-3.624 62.655a3.216 3.216 0 0 1-3.211 3.031h-22.498a3.217 3.217 0 0 1-3.212-3.409l3.78-62.655a3.213 3.213 0 0 1 3.209-3.024h22.347a3.215 3.215 0 0 1 3.21 3.402h-.001z"
                                      fill="#EEE"></path>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="m159.301-7.454-5.378 69.624a3.208 3.208 0 0 1-1.225 2.286 3.219 3.219 0 0 1-2.514.638L113.76 58.98a3.225 3.225 0 0 1-2.109-1.337 3.212 3.212 0 0 1-.517-2.443L123.34-8.307a3.217 3.217 0 0 1 3.161-2.61h29.595a3.219 3.219 0 0 1 3.207 3.464h-.002zm-44.967 76.067 35.616 6.818a3.213 3.213 0 0 1 2.603 3.374l-3.862 57.82a3.217 3.217 0 0 1-3.209 3.002h-31.753a3.216 3.216 0 0 1-3.216-3.216v-64.64a3.226 3.226 0 0 1 1.167-2.478 3.215 3.215 0 0 1 2.654-.68z"
                                      fill="#A7E29E"></path>
                                <path
                                    d="M142.616 149.771H116.05a4 4 0 0 0-3.995 4.202l1.581 31.227a4 4 0 0 0 3.995 3.798h21.427a4 4 0 0 0 3.974-3.548l3.558-31.227a4 4 0 0 0-3.974-4.452z"
                                    fill="#A7E29E"></path>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M160.703 131.258h24.407a3.218 3.218 0 0 1 3.162 2.628l9.953 53.407a3.208 3.208 0 0 1-.688 2.645 3.207 3.207 0 0 1-2.474 1.161h-38.824a3.209 3.209 0 0 1-2.366-1.038 3.207 3.207 0 0 1-.839-2.445l4.464-53.407a3.218 3.218 0 0 1 3.205-2.949v-.002zM324.51 69.601l117.926 111.732a3.216 3.216 0 0 1-2.213 5.551H225.689a3.216 3.216 0 0 1-2.433-5.32L319.867 69.83a3.23 3.23 0 0 1 2.273-1.108 3.22 3.22 0 0 1 2.372.878l-.002.002z"
                                      fill="#EEE"></path>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M468.585-14.945c36.524 0 62.724 77.985 153.688 125.165 60.642 31.453 100.455 41.103 119.44 28.949l-39.427-158.61c-180.15 2.997-258.049 4.495-233.701 4.495z"
                                      fill="#FFD24D"></path>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M495.994-22.504c36.523 0 62.723 77.986 153.686 125.166 60.644 31.453 100.457 41.103 119.443 28.947L729.694-27c-180.149 2.998-258.049 4.496-233.7 4.496z"
                                      fill="#97C0D7"></path>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="m263.352-10.912 91.771 83.323a3.216 3.216 0 0 0 4.543-.218l45.633-50.233a3.218 3.218 0 0 0-.161-4.49L377.91-8.503a3.214 3.214 0 0 0-2.013-.883l-110.175-7.117a3.215 3.215 0 0 0-3.35 3.9c.143.652.486 1.243.98 1.691zm109.01 94.917 46.835-50.143a3.201 3.201 0 0 1 2.253-1.02 3.213 3.213 0 0 1 2.31.882l65.121 61.766a3.227 3.227 0 0 1 1.003 2.37 3.211 3.211 0 0 1-1.056 2.347l-50.781 46.073a3.22 3.22 0 0 1-4.367-.041l-61.175-57.7a3.218 3.218 0 0 1-.144-4.534h.001zm76.651 73.959 35.719-32.4a3.216 3.216 0 0 1 4.347.023l73.687 68.279a3.213 3.213 0 0 1 .713 3.752 3.217 3.217 0 0 1-3.376 1.788l-82.471-12.383a3.215 3.215 0 0 1-1.637-.756l-26.933-23.497a3.223 3.223 0 0 1-.835-3.712c.182-.416.45-.789.786-1.094zM459.928-8.44l22.659 21.247a3.214 3.214 0 0 1 .663 3.812 3.224 3.224 0 0 1-.872 1.06l-22.863 18.028a3.218 3.218 0 0 1-4.19-.177L413.356-3.746a3.213 3.213 0 0 1-.793-3.53 3.218 3.218 0 0 1 2.991-2.034h42.174c.817 0 1.604.31 2.2.87zm15.157 55.184 20.417-16.854a3.216 3.216 0 0 1 4.435.327l31.052 34.43a3.219 3.219 0 0 1 .404 3.749c-.223.39-.525.729-.887.995l-19.704 14.495a3.217 3.217 0 0 1-4.191-.328l-31.763-32.07a3.227 3.227 0 0 1-.928-2.424 3.219 3.219 0 0 1 1.165-2.32zm28.274 68.629 38.709-31.315a3.215 3.215 0 0 1 3.923-.094c53.742 39.423 105.81 64.064 156.21 73.927 51.022 9.987 80.367 40.862 36.396 74.267L592.638 192.92l-89.285-72.55a3.215 3.215 0 0 1 .006-4.997z"
                                      fill="#EEE"></path>
                                <path
                                    d="M459.53 201.412H226.854a3.999 3.999 0 0 0-3.896 3.093l-8.26 35.483a4 4 0 0 0 3.895 4.907h373.084c3.429 0 5.269-4.032 3.021-6.621l-19.263-22.197a3.996 3.996 0 0 0-2.554-1.351L459.53 201.412z"
                                    fill="#EEE" stroke="#EEE"></path>
                                <path
                                    d="M750.33 251.071v121.714a2 2 0 0 1-2.203 1.99l-242.84-24.789c-2.449-.25-2.371-3.843.087-3.986l189.904-11.067c1.653-.096 2.48-2.045 1.399-3.301l-57.05-66.315-43.932-50.039c-1.291-1.47.08-3.728 1.98-3.262l151.132 37.112a2.001 2.001 0 0 1 1.523 1.943z"
                                    fill="#EEE"></path>
                                <path
                                    d="M621.008 338.269h40.765c1.672 0 2.606-1.928 1.57-3.239l-48.191-61.013c-2.024-2.562-6.1-1.751-6.988 1.392l-16.327 57.772c-.721 2.553 1.197 5.088 3.849 5.088h25.322z"
                                    fill="#EEE"></path>
                                <path
                                    d="M197.825 205.384H63.449a4 4 0 0 0-3.96 3.429l-4.348 30.161a4 4 0 0 0 3.96 4.571h128.342a3.999 3.999 0 0 0 3.782-2.698l10.383-30.161c.894-2.597-1.036-5.302-3.783-5.302zM46.694 160.695H3.405a4 4 0 0 0-4 4v74.923a4 4 0 0 0 4 4h29.626a4 4 0 0 0 3.935-3.282l13.663-74.923a4 4 0 0 0-3.935-4.718zM27.288 263.787H-16a4 4 0 0 0-4 4v74.923a4 4 0 0 0 4 4h29.625a4 4 0 0 0 3.935-3.282l13.663-74.924a4 4 0 0 0-3.935-4.717zm67.592-78.265H69.103a4 4 0 0 1-3.915-4.823L93.22 47.335c.492-2.338 3.927-2.003 3.958.386l1.703 133.75a4 4 0 0 1-4 4.051zM36.243 19.34 34.38-12.325v-8.893a4 4 0 0 1 4.713-3.936l66.692 12.091a4 4 0 0 1 3.196 4.781L102.86 20.04a4 4 0 0 1-2.452 2.88l-25.23 9.867a4 4 0 0 1-2.597.109l-33.486-9.958a4 4 0 0 1-2.853-3.6zm-11.3 114.227 12.335-59.899c.055-.266.082-.536.082-.807V44.153a2 2 0 0 1 2.534-1.927l32.632 9.044a4 4 0 0 1 2.836 4.722l-17.614 79.249a4 4 0 0 1-3.905 3.133H28.861a4 4 0 0 1-3.918-4.807zm-1.897-64.732-2.42-78.772a4 4 0 0 0-3.998-3.877H3.126a4 4 0 0 0-4 4v142.891a4 4 0 0 0 4.548 3.963l2.158-.299a4 4 0 0 0 3.349-3.061l3.178-13.741 5.14-23.3 5.468-26.883c.062-.303.088-.612.079-.92z"
                                    fill="#EEE" stroke="#EEE"></path>
                                <path
                                    d="m450.57 266.649-7.673 42.721-7.816 43.517a4 4 0 0 1-4.663 3.227l-156.763-28.918c-2.701-.498-4.108-3.508-2.758-5.9l31.356-55.54a4 4 0 0 1 3.432-2.033l140.897-1.78a4 4 0 0 1 3.988 4.706z"
                                    fill="#A7E29E"></path>
                                <path
                                    d="m509.391 261.29 39.615 1.027 37.024.96c3.086.08 4.922 3.479 3.297 6.104l-7.914 12.786a4.002 4.002 0 0 1-3.617 1.889l-72.277-3.895c-2.626-.142-4.404-2.736-3.587-5.236l3.553-10.879a4 4 0 0 1 3.906-2.756zm-9.09 32.421 37.399 3.569 37.143 3.064c2.585.213 4.287 2.794 3.464 5.254l-9.644 28.854a4 4 0 0 1-3.974 2.728l-72.12-3.253a4 4 0 0 1-3.724-4.865l7.172-32.237a4 4 0 0 1 4.284-3.114z"
                                    fill="#EEE" stroke="#EEE"></path>
                                <path
                                    d="m396.895 337.209 29.16-.731 42.24-1.06a1.999 1.999 0 0 0 1.916-1.633l12.741-68.432a4 4 0 0 0-4.033-4.731l-76.039 1.907a4 4 0 0 0-3.883 3.629l-6.186 66.683a4 4 0 0 0 4.084 4.368z"
                                    fill="#A7E29E"></path>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="m82.088 338.865 31.127-1.902a4.825 4.825 0 0 0 2.475-.864l59.572-41.723a3.18 3.18 0 0 0 .729-.703l20.975-27.988a3.215 3.215 0 0 0-.828-4.63 3.217 3.217 0 0 0-1.659-.514l-103.573-2.807a3.215 3.215 0 0 0-3.281 2.832l-8.926 74.705a3.216 3.216 0 0 0 3.389 3.594zm-20.999.113-15.451.842a3.22 3.22 0 0 1-2.536-1.027 3.214 3.214 0 0 1-.827-2.608l10.569-79.356a3.215 3.215 0 0 1 3.541-2.773l11.778 1.304a3.216 3.216 0 0 1 2.849 3.482l-6.894 77.21a3.218 3.218 0 0 1-3.029 2.926z"
                                      fill="#EEE"></path>
                                <path
                                    d="m275.24 263.119-48.499-1.113a3.999 3.999 0 0 0-3.573 2.03l-22.316 39.453c-.29.512-.688.953-1.167 1.293l-40.27 28.584c-3.14 2.229-1.626 7.172 2.224 7.261l66.314 1.522a4.002 4.002 0 0 0 3.425-1.788l47.103-71.032c1.741-2.624-.093-6.137-3.241-6.21z"
                                    fill="#EEE"></path>
                                <path
                                    d="m390.633 331.115-110.778 2.449-11.221-.257-2.054.054c-3.216.086-5.212-3.465-3.468-6.167l35.785-55.436a4 4 0 0 1 5.425-1.257l88.287 53.189c3.432 2.068 2.03 7.337-1.976 7.425z"
                                    fill="#A7E29E"></path>
                                <path
                                    d="M379.772 290.449 369.1 322.204a3.926 3.926 0 0 0-.152.604l-3.317 19.488a4 4 0 0 1-4.367 3.306l-66.717-7.103c-2.949-.313-4.543-3.613-2.956-6.118l28.732-45.347a3.997 3.997 0 0 1 3.378-1.859l52.28-.001c2.734 0 4.662 2.683 3.791 5.275z"
                                    fill="#FEF9EA"></path>
                            </g>
                            <defs>
                                <clipPath id="map_svg__a">
                                    <path d="M0 0h720v330H0V0z" fill="#fff"></path>
                                </clipPath>
                            </defs>
                        </svg>
                        <div className="showMapButton">
                            <span onClick={this.loadGoogleMap}>{i18next.t('showMap')}</span>
                        </div>
                    </section>
                }
                <section id={'map'} className={(this.props.booking.apartment.address.displayMap || this.state.showMap ? '' : 'd-none')} style={{ 'padding': '0' }}>
                    <div className={'map'} ref={this.mapRef} style={{ 'height': '600px' }} />
                </section>
            </>
        );
    }
}