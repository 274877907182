import React, { Component } from 'react';
import { connect } from 'react-redux';
import { parse } from 'query-string';
import i18next from 'i18next';
import { createOrder } from "../../actions/CrossSellingAction";

class StripeConnectPaymentConfirmationView extends Component {

    state = {
        errorMessage : ''
    }



    componentDidMount() {

        const params = parse(document.location.search);

        if (!params.payment_intent || params.redirect_status !== 'succeeded') {

            this.setState({ errorMessage: i18next.t('Payment failed, please try again later.') });

            return;
        }

        let cartItems;
        if (localStorage.getItem('cartItems')) {
            cartItems = JSON.parse(localStorage.getItem('cartItems'))
        }

        if (!cartItems && params.cartItems) {
            cartItems = JSON.parse(params.cartItems)
        }

        if (!cartItems) {

            console.error('No cart items found, something went wrong');
            return;
        }

        localStorage.setItem('cartItems', null)
        const addtionalParams = { stripeToken: params.payment_intent };
        this.props.dispatch(createOrder(cartItems, params.paymentMethodId, addtionalParams))
    }

    render() {

        return (
            <div className={'mt-5'}>
                {this.state.errorMessage && <p className={'alert alert-danger'}>{this.state.errorMessage}</p>}
                {this.props.order.errorMessage && <p className={'alert alert-danger'}>{this.props.order.errorMessage}</p>}
                {this.props.order.successMessage && <p className={'alert alert-success'}>{this.props.order.successMessage}</p>}
                <a href={'/'}>{i18next.t('Go back')}</a>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        order: state.order
    };
};

export default connect(mapStateToProps)(StripeConnectPaymentConfirmationView);