import {
    FETCH_PRODUCTS_START, FETCH_PRODUCTS_SUCCESS, FETCH_PRODUCTS_ERROR, PRODUCT_LIST_SHOW, PRODUCT_LIST_HIDE,
} from '../../actions/CrossSellingAction';
import i18next from "i18next";

const initialState = {
    products: [],
    errorMessage: '',
    loading: false,
    showProductList : true
};

export default function productListReducer(state = initialState, action) {
    const payload = action.payload;
    switch (action.type) {
        case FETCH_PRODUCTS_START: {
            return { ...initialState, loading: true };
        }
        case FETCH_PRODUCTS_SUCCESS: {
            return { ...initialState, products: payload }
        }
        case FETCH_PRODUCTS_ERROR: {
            return { ...initialState, errorMessage: i18next.t('Ooops, something went wrong') }
        }
        case PRODUCT_LIST_SHOW: {
            return { ...state, showProductList: true }
        }
        case PRODUCT_LIST_HIDE: {
            return { ...state, showProductList: false }
        }
        default:
            return state;
    }
}