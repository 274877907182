/**
 *
 * @param sectionId id
 */
export function moveToSection(sectionId) {
    const { jQuery } = window;

    const element = jQuery(`#${sectionId}`);

    if (!element.length) {
        return;
    }

    jQuery('html, body').animate({
        scrollTop: sectionId === 'home' ? 0 : element.prev().offset().top - 20
    }, 1000);

    jQuery('body').removeClass('sidebar-mobile-main');
}