import axios from "axios";
import { session } from "../utils/Session";

export const FETCH_PRODUCTS_START = 'FETCH_PRODUCTS_START';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR';
export const PRODUCT_LIST_SHOW = 'PRODUCT_LIST_SHOW';
export const PRODUCT_LIST_HIDE = 'PRODUCT_LIST_HIDE';


export const CART_ADD_ITEM = 'CART_ADD_ITEM';
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM';
export const CART_RESET = 'CART_RESET';
export const CART_SHOW = 'CART_SHOW';
export const CART_HIDE = 'CART_HIDE';

export const FETCH_PAYMENT_METHODS_SUCCESS = "FETCH_PAYMENT_METHODS_SUCCESS";
export const FETCH_PAYMENT_METHODS_ERROR = "FETCH_PAYMENT_METHODS_ERROR";

export const CREATE_ORDER_START = "CREATE_ORDER_START";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_ERROR = "CREATE_ORDER_ERROR";

export const DISABLE_ORDER_BUTTON = "DISABLE_ORDER_BUTTON";
export const ENABLE_ORDER_BUTTON = "ENABLE_ORDER_BUTTON";

export function fetchProducts(bookingId) {
    return function (dispatch) {
        dispatch({ type: FETCH_PRODUCTS_START });
        const request = axios.get(`/bookings/${bookingId}/additional-items`);
        request.then((res) => {
            dispatch({
                payload: res.data['additionalItems'] ? res.data['additionalItems'] : [],
                type: FETCH_PRODUCTS_SUCCESS
            });
        }).catch((e) => {
            dispatch({ type: FETCH_PRODUCTS_ERROR })
        });
    }
}

export function fetchPaymentMethods(bookingId) {
    return function (dispatch) {
        const request = axios.get(`/bookings/${bookingId}/payment/information`);
        request.then((res) => {
            dispatch({
                payload: res.data['paymentMethods'] ? res.data['paymentMethods'] : [],
                type: FETCH_PAYMENT_METHODS_SUCCESS
            });
        }).catch((e) => {
            dispatch({ type: FETCH_PAYMENT_METHODS_ERROR })
        });
    }
}

export function addToCart(product, quantity) {
    return function (dispatch) {
        const payload = { product, quantity };
        dispatch({ payload, type: CART_ADD_ITEM });
    }
}

export function removeFromCart(product) {
    return function (dispatch) {
        const payload = { product };
        dispatch({ payload, type: CART_REMOVE_ITEM });
    }
}

export function createOrder(cartItems, paymentMethod, additionalParams = {}) {
    return function (dispatch) {
        dispatch({ type: CREATE_ORDER_START });

        const data = {
            additionalItems : [],
            paymentMethod,
            ...additionalParams
        };

        for(let cartItem of cartItems){
            data.additionalItems.push({
               id : cartItem.product.id,
               quantity: cartItem.quantity
            });
        }
        const bookingId = session.getItem('bookingId');
        const request = axios.post(`/bookings/${bookingId}/additional-items`, JSON.stringify(data));
        request.then((res) => {
            res = res.data;
            if (res.success) {
                dispatch({ type: CREATE_ORDER_SUCCESS });
                disableOrderButton();
                setTimeout(() => {
                    dispatch({ type: CART_RESET });
                    dispatch(fetchProducts(bookingId));
                }, 10000);
            } else {
                dispatch({ type: CREATE_ORDER_ERROR });
            }
        }).catch((error) => {
            console.log(error);
            dispatch({type: CREATE_ORDER_ERROR });
        });
    }
}

export function disableOrderButton() {
    return function (dispatch) {
        dispatch({ type: DISABLE_ORDER_BUTTON });
    }
}

export function enableOrderButton() {
    return function (dispatch) {
        dispatch({ type: ENABLE_ORDER_BUTTON });
    }
}

export function showCart() {
    return function (dispatch) {
        dispatch({ type: CART_SHOW });
    }
}

export function hideCart() {
    return function (dispatch) {
        dispatch({ type: CART_HIDE });
    }
}

export function showProductList() {
    return function (dispatch) {
        dispatch({ type: PRODUCT_LIST_SHOW });
    }
}

export function hideProductList() {
    return function (dispatch) {
        dispatch({ type: PRODUCT_LIST_HIDE });
    }
}