import React, { Component } from 'react';
import ContentListContainer from '../containers/ContentListContainer';
import WeatherContainer from "../containers/WeatherContainer";
import TourListContainer from "../containers/TourListContainer";
import Loading from "./common/Loading";
import i18next from "i18next";
import { currentPeriod } from "../utils/DisplayTimePeriodUtils";
import { moveToSection } from "../utils/Menu";
import { canDisplayFeature } from "../utils/CanDisplayFeature";
import ReviewContainer from "../containers/ReviewContainer";
/*import OnlineCheckInContainer from "../containers/OnlineCheckInContainer";
import BookNextContainer from "../containers/BookingToolContainer";*/
import { canDisplayContent } from "../utils/CanDisplayContent";
import MapView from "./MapView";
import MessageView from "./message/MessageView";
import ProductListView from "./cross-selling/ProductListView";
import CartView from "./cross-selling/CartView";
import { dateTimeFormat } from "../utils/Localization";

export default class extends Component {

    menuItems = [
        { name: 'online-check-in', label: 'Online Check-in', icon : 'online-check-in.svg' },
        { name: 'tours', label: 'Tours', icon : 'tours.svg' },
        { name: 'review', label: 'Leave review', icon : 'review.svg' },
        { name: 'booking-tool', label: 'Book next stay', icon : 'booking-tool.svg' },
        { name: 'message', label: 'Message', icon : 'message.svg' },
        { name: 'luggage-storage-luggage-hero', label: 'Luggage Storage', icon : 'luggage-hero.png' },
        { name: 'luggage-storage-nanny-bag', label: 'Luggage Storage', icon : 'nanny-bag.png' },
        { name: 'additional-items', label: 'Book additional items', icon : 'add-cart-item.svg' },
    ];

    handleMenuItemClick = (event) => {
        event.preventDefault();
        const sectionId = event.target.closest('a').dataset.sectionId;

        if (sectionId === 'online-check-in') {
            window.open(this.props.booking.onlineCheckInUrl, '_blank');
            return;
        }

        if (sectionId === 'booking-tool') {
            window.open(this.props.booking.bookingToolUrl, '_blank');
            return;
        }

        if (sectionId === 'luggage-storage-luggage-hero') {
            window.open(this.props.booking.luggageStorageLuggageHeroUrl, '_blank');
            return;
        }

        if (sectionId === 'luggage-storage-nanny-bag') {
            window.open(this.props.booking.luggageStorageNannybagUrl, '_blank');
            return;
        }

        moveToSection(sectionId);
    };

    render() {
        const { booking, contents, tours, products } = this.props;

        if (!booking) {
            return <Loading />
        }
        const currentTimePeriod = currentPeriod(booking);
        const { features } = booking.settings;
        const { address } = booking.apartment;

        return (
            <div className={'sections'}>
                <section id={'home'} className={'home'}>
                    <div className={'row summery'}>
                        <div className={'col-10 col-sm-12'}>
                            <p>
                                <span className={'guest-name'}>{i18next.t('Hello')} {booking.guestName}</span> <br />
                            <span className={'booking-dates'}> {dateTimeFormat.format(new Date(`${booking.arrivalDate}T00:00`)) + ' - ' + dateTimeFormat.format(new Date(`${booking.departureDate}T00:00`))}</span>
                            </p>
                            <div className={'address-map'}>
                                {address.street &&
                                <span className={'address'}>
                                    <span className={'apartment-name'}>{booking.apartment.name}</span> <br />
                                    {address.street} <br />
                                    {address.postalCode} {address.city}
                                </span>
                                }
                            </div>
                        </div>
                    </div>
                    {typeof features['weather'] !== 'undefined' && canDisplayFeature(features['weather'], currentTimePeriod) &&

                    <WeatherContainer />

                    }
                    <div className={'menu-secondary'}>
                        <div className={'row'}>
                            {this.menuItems.map((mentItem, index) => {

                                if (typeof features[mentItem.name] !== 'undefined' && canDisplayFeature(features[mentItem.name], currentTimePeriod)) {

                                    if (mentItem.name === 'tours' && tours.length === 0) {
                                        return '';
                                    }

                                    if (mentItem.name === 'additional-items' && products.length === 0) {
                                        return '';
                                    }

                                    return (
                                        <div key={index} className={'col-6'}>
                                            <div className={'menu-item'}>
                                            <a
                                                href="/#"
                                                data-section-id={mentItem.name}
                                                onClick={this.handleMenuItemClick}
                                                key={index}
                                            >
                                                <div className={'row'}>
                                                    <div className={'col-4'}>
                                                        <img src={`/images/icons/${mentItem.icon}`} alt="" />
                                                    </div>
                                                    <div className={'col-8'} style={{position : 'relative'}}>
                                                        <span className={'label'}>{i18next.t(mentItem.label)}</span>
                                                    </div>
                                                </div>
                                            </a>
                                            </div>
                                        </div>
                                    );
                                }

                                return '';
                            })}

                            {
                                contents.map((content, index) => {

                                    if (canDisplayContent(content, currentTimePeriod)) {

                                        return (
                                            <div className={'col-6'} key={index}>
                                                <div className={'menu-item'}>
                                                    <a
                                                        href="/#"
                                                        data-section-id={`content-${content.id}`}
                                                        onClick={this.handleMenuItemClick}
                                                        key={index}
                                                    >
                                                        <div className={'row'}>
                                                            <div className={'col-4'}>
                                                                <img src={content.icon ? content.icon : `/images/icons/info.svg`}  alt=""/>
                                                            </div>
                                                            <div className={'col-8'} style={{ position: 'relative' }}>
                                                                <span className={'label'}>{content.title}</span>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>

                                            </div>
                                        );
                                    }
                                    return '';
                                })
                            }
                        </div>
                    </div>
                    <div className={'clearfix'} />
                </section>


                {/*{typeof features['online-check-in'] !== 'undefined' && canDisplayFeature(features['online-check-in'], currentTimePeriod) &&

                <OnlineCheckInContainer />

                }*/}
                {typeof features['map'] !== 'undefined' && canDisplayFeature(features['map'], currentTimePeriod) &&

                <MapView booking={booking} />

                }
                {typeof features['message'] !== 'undefined' && canDisplayFeature(features['message'], currentTimePeriod) &&

                <MessageView />

                }
                {typeof features['additional-items'] !== 'undefined'  && products.length > 0 && canDisplayFeature(features['additional-items'], currentTimePeriod) &&
                <>
                    <h1 className="section-heading">{i18next.t('Book additional items')}</h1>
                    <section id={'additional-items'}>
                        <ProductListView />
                        <CartView />
                    </section>
                </>
                }

                <ContentListContainer />

                {typeof features['tours'] !== 'undefined' && canDisplayFeature(features['tours'], currentTimePeriod) &&

                <TourListContainer />

                }


                {/*{typeof features['booking-tool'] !== 'undefined' && canDisplayFeature(features['booking-tool'], currentTimePeriod) &&

                <BookNextContainer />

                }*/}

                {typeof features['review'] !== 'undefined' && canDisplayFeature(features['review'], currentTimePeriod) &&

                <ReviewContainer />

                }


            </div>
        );
    }
}