import { connect } from 'react-redux';
import ContentListView from '../views/ContentListView';
import { fetchContents } from "../actions/ContentAction";

const mapStateToProps = (state) => {
    return {
        contentList: state.contentList,
        currentBooking: state.currentBooking,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchContents: (bookingId) => dispatch(fetchContents(bookingId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentListView);