export const IS_AUTHENTICATED = 'isAuthenticated';
export const TOKEN = 'token';
export const BOOKING_ID = 'bookingId';
export const PIN_CODE = 'pinCode';
export const VALID_UNTIL = 'validUntil';

class Session {

    constructor() {

        if (typeof localStorage != 'undefined') {
            this.storage = localStorage;
        } else {
            this.storage = {};
        }
    }

    /**
     * Get an item from session
     *
     * @param key {string}
     * @returns {string|any|null}
     */
    getItem(key) {

        if (this.storage instanceof Storage) {

            return this.storage.getItem(key);
        }

        return this.storage.hasOwnProperty(key) ? this.storage[key] : null;
    }

    /**
     * Set an item to session
     *
     * @param key {string}
     * @param value {number}
     */
    setItem(key, value) {

        if (this.storage instanceof Storage) {

            this.storage.setItem(key, value);
        } else {

            this.storage[key] = value;
        }
    }

    reset() {

        if (this.storage instanceof Storage) {
            this.storage.clear();
        } else {
            this.storage = {};
        }
    }

    isAuthenticated() {

        return parseInt(this.getItem(IS_AUTHENTICATED)) === 1
    }
}

export const session = new Session();