import React, { Component } from 'react';
import { connect } from "react-redux";
import Loading from "./Loading";
import i18next from "i18next";

const DIRECTION_TOP = 1;
const DIRECTION_BOTTOM = 2;

class Footer extends Component {
    state = {
        showImprint : false
    }

    scrollTo(direction = DIRECTION_TOP) {

        window.jQuery('html, body').animate({
            scrollTop: direction === DIRECTION_TOP ? 0 : window.jQuery(document).height()
        }, 1000);
    }

    render() {

        const { booking } = this.props;

        if (!booking) {

            return <Loading />;
        }

        const { contact } = booking.user;

        const nameCompanyStr = [
            (contact.firstName + ' ' + contact.lastName).trim(),
            contact.company
        ].filter(element => element).join(', ');

        const { address } = contact;
        const addressStr = [
            address.street,
            (address.postalCode + ' ' + address.city).trim(),
            address.country
        ].filter(element => element).join(', ');

        const phone = contact.phone || '';
        const mobile = contact.mobile || '';
        const email = contact.email || '';

        return (
            <footer>
                <>
                    <a href={'/#'} onClick={(event) => {
                        event.preventDefault();
                        this.setState({ showImprint: true });
                        this.scrollTo(DIRECTION_BOTTOM);
                    }}>{i18next.t('Imprint')}</a>

                    <div style={!this.state.showImprint ? { 'display': 'none' } : {}}>
                        <h3 className='mt-3'>{i18next.t('Contact')}</h3>
                        <p>
                            {
                                nameCompanyStr.length > 0 && <>{nameCompanyStr}<br /></>
                            }
                            {
                                addressStr.length > 0 && <>{addressStr} <br /></>
                            }
                            {
                                phone.length > 0 && <><i className='fa fa-phone' /> {phone} <br /></>
                            }
                            {
                                mobile.length > 0 && <><i className='fa fa-phone' /> {mobile} (mobile) <br /></>
                            }

                            {
                                email && <>
                                    <i className='fa fa-envelope' />
                                    <a className='font-color-blue' href={'mailto:' + email}> {email}</a>
                                    <br />
                                </>
                            }
                        </p>
                        <div dangerouslySetInnerHTML={{ __html: booking.user.imprint }} />
                    </div>
                </>

                <p className='text-center'>
                    Guest App powered by <a href={'https://www.smoobu.com/'} target={'_blank'}> Smoobu.com </a>
                </p>
                <button className={'scroll-to-top-btn btn btn-default'} onClick={() => {
                    this.scrollTo(DIRECTION_TOP)
                }}>
                    <i className="fas fa-angle-up" /></button>
            </footer>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        booking : state.currentBooking.booking,
    };
};

export default connect(mapStateToProps)(Footer);


