import React, { Component } from 'react';
import i18next from "i18next";
import Error from "./common/Error";
import { connect } from "react-redux";
import { checkBookingWithoutPinCode, fetchBooking } from "../actions/BookingAction";
import axios from "axios";
import { session, PIN_CODE, IS_AUTHENTICATED, VALID_UNTIL, BOOKING_ID } from "../utils/Session";
import moment from "moment";
import Loading from "./common/Loading";

class AuthView extends Component {

    constructor(props) {
        super(props);

        this.formData = {};
        this.state = { checkBookingWithoutPinCode: true };
    }


    handleChange = (event) => {
        this.formData[event.target.getAttribute('name')] = event.target.value;
    };

    handleSubmit(event) {
        event.preventDefault();

        if (!this.formData.pinCode) {
            return;
        }

        axios.defaults.params.pinCode = this.formData.pinCode;
        this.props.dispatch(fetchBooking(session.getItem(BOOKING_ID)));
    }

    componentDidMount() {
        // try to fetch booking without pincode as pincode is optional
        this.props.dispatch(checkBookingWithoutPinCode(session.getItem(BOOKING_ID)));
    }

    render() {

        const { loading, successMessage, errorMessage, booking } = this.props.currentBooking;

        if (booking || this.props.checkBookingWithoutPinCode.booking) {
            session.setItem(PIN_CODE, this.formData.pinCode ? this.formData.pinCode : null);
            session.setItem(IS_AUTHENTICATED, 1);
            session.setItem(VALID_UNTIL, moment().add(2, 'days').unix());
            window.location.reload();
        }

        if(this.props.checkBookingWithoutPinCode.done && this.props.checkBookingWithoutPinCode.errorMessage.includes('Guest app is disabled')) {
            return (
                <Error message={i18next.t('Invalid link')} />
            );
        }

        return (
            <>
                {this.props.checkBookingWithoutPinCode.done && !this.props.checkBookingWithoutPinCode.booking &&
                    <>
                        <h1 className={'section-heading'}>{i18next.t('Welcome guest!')}</h1>
                        <div id={'auth'} className={'auth'}>
                            {successMessage &&
                                <div className={'alert alert-success'}>{successMessage}</div>
                            }
                            {!successMessage &&
                                <form onSubmit={this.handleSubmit.bind(this)}>
                                    <div className={'row justify-content-center'}>
                                        <div className={'col-6'}>
                                            <div className={'form-group'}>
                                                <input type="text"
                                                       className="form-control"
                                                       placeholder={i18next.t('Enter your pin code here')}
                                                       name="pinCode"
                                                       onChange={this.handleChange.bind(this)}
                                                />
                                            </div>
                                            <div className={'form-group mt-1'}>
                                                <div className="col-form-label">
                                                    <div className={'d-flex align-items-end flex-column'}>
                                                        <button type={'submit'} className={'btn btn-primary'}>{i18next.t('Submit')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {loading &&
                                        <div>{i18next.t('Working')}</div>
                                    }
                                    {errorMessage &&
                                        <Error message={errorMessage} />
                                    }
                                </form>
                            }
                        </div>
                    </>
                }

                {!this.props.checkBookingWithoutPinCode.done &&
                    <Loading />
                }

            </>
        );
    }
}

const mapStateToProps = (state) => {

    return {
        currentBooking: state.currentBooking,
        checkBookingWithoutPinCode: state.checkBookingWithoutPinCode,
    };
};

export default connect(mapStateToProps)(AuthView);