import React from 'react';

export default ({ message }) => {
    if (message) {
        return (
            <div className="alert alert-danger">
                {message}
            </div>
        );
    }

    return null;
}