import i18next from "i18next";
import {
    CREATE_MESSAGE_START, CREATE_MESSAGE_SUCCESS, CREATE_MESSAGE_ERROR, CREATE_MESSAGE_RESET,
} from '../../actions/MessageAction';

const initialState = {
    validationMessages: [],
    successMessage: '',
    errorMessage: '',
    loading: false,
};

export default function createMessageReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_MESSAGE_START: {
            return { ...initialState, loading: true };
        }
        case CREATE_MESSAGE_SUCCESS: {
            return { ...initialState, successMessage: i18next.t('Message sent') }
        }
        case CREATE_MESSAGE_ERROR: {
            return { ...initialState, errorMessage: i18next.t('Ooops, something went wrong') }
        }
        case CREATE_MESSAGE_RESET: {
            return { ...initialState }
        }
        default:
            return state;
    }
}