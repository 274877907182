import axios from "axios";

export const CREATE_REVIEW_START = 'CREATE_REVIEW_START';
export const CREATE_REVIEW_SUCCESS = 'CREATE_REVIEW_SUCCESS';
export const CREATE_REVIEW_ERROR = 'CREATE_REVIEW_ERROR';


export function createReview(bookingId, data) {
    return function (dispatch) {
        dispatch({ type: CREATE_REVIEW_START });
        const request = axios.post(`/bookings/${bookingId}/review`, JSON.stringify(data));
        request.then((res) => {
            dispatch({ type: CREATE_REVIEW_SUCCESS });
        }).catch((e) => {
            dispatch({ type: CREATE_REVIEW_ERROR })
        });
    }
}