import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from "../common/Loading";
import i18next from 'i18next';
import { removeFromCart } from "../../actions/CrossSellingAction";
import CheckoutView from "./CheckoutView";
import { numberFormat } from "../../utils/Localization";

class CartView extends Component {

    render() {
        const { items, loading, showCart } = this.props.cart;
        if (loading) {
            return <Loading />;
        }

        if (!items.length) {
            return '';
        }

        const totalPrice = items.reduce((total, item) => total + (item.product.price * item.quantity), 0);
        const currencySymbol = items.length ? items[0]['product']['currency']['symbol'] : '';

        return (
            <div className={'cart-section'}>
                <div id={'cart'} style={!showCart ? { 'display': 'none' } : {}}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <table className="table cart-items">
                                <thead>
                                    <tr>
                                        <th>{i18next.t('Item')}</th>
                                        <th style={{'textAlign' : 'right'}}>{i18next.t('Price')}</th>
                                        <th style={{'textAlign' : 'right'}}>{i18next.t('Total')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map((item, index) => {
                                        const { product, quantity } = item;
                                        return (
                                            <tr key={index}>
                                                <td>{product.name}</td>
                                                <td style={{'textAlign' : 'right'}}>{product.currency.symbol} {numberFormat.format(product.price)} x {quantity} / {product.tax}% VAT</td>
                                                <td style={{'textAlign' : 'right'}}>{currencySymbol} {numberFormat.format(product.price * quantity)}
                                                    <span
                                                        title={i18next.t('Remove this item from the cart')}
                                                        style={{ cursor: 'pointer', marginLeft : '5px' }}
                                                        onClick={() => {
                                                            this.props.dispatch(removeFromCart(product))
                                                        }}><i className="far fa-times-circle fa-1x" /></span></td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td colSpan={2} />
                                        <td style={{'textAlign' : 'right'}}>{currencySymbol} {numberFormat.format(totalPrice)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <CheckoutView />
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        cart: state.cart,
    };
};

export default connect(mapStateToProps)(CartView);