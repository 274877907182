import React, { Component } from 'react';
import { moveToSection } from "../utils/Menu";
import { currentPeriod } from "../utils/DisplayTimePeriodUtils";
import { canDisplayFeature } from "../utils/CanDisplayFeature";
import i18next from "i18next";
import { canDisplayContent } from "../utils/CanDisplayContent";


export default class extends Component {

    menuItems = [
        { name: 'map', label: 'Map' },
        { name: 'weather', label: 'Weather' },
        { name: 'online-check-in', label: 'Online Check-in' },
        { name: 'tours', label: 'Tours', },
        { name: 'review', label: 'Leave review' },
        { name: 'booking-tool', label: 'Book next stay' },
        { name: 'message', label: 'Message', icon : 'message.png' },
        { name: 'luggage-storage-luggage-hero', label: 'Luggage Storage', icon : 'luggage-hero.png' },
        { name: 'luggage-storage-nanny-bag', label: 'Luggage Storage', icon : 'nanny-bag.png' },
        { name: 'additional-items', label: 'Book additional items', icon : 'add-cart-item.svg' },
    ];

    handleMenuItemClick = (event) => {
        event.preventDefault();

        const { sectionId } = event.target.dataset;

        if (sectionId === 'online-check-in') {
            window.open(this.props.booking.onlineCheckInUrl, '_blank');
            return;
        }

        if (sectionId === 'booking-tool') {
            window.open(this.props.booking.bookingToolUrl, '_blank');
            return;
        }

        if (sectionId === 'luggage-storage-luggage-hero') {
            window.open(this.props.booking.luggageStorageLuggageHeroUrl, '_blank');
            return;
        }

        if (sectionId === 'luggage-storage-nanny-bag') {
            window.open(this.props.booking.luggageStorageNannybagUrl, '_blank');
            return;
        }

        moveToSection(sectionId);
    };

    render() {

        const { booking } = this.props;

        let currentTimePeriod = null, features = null;

        if (booking) {
            currentTimePeriod = currentPeriod(booking);
            features = booking.settings.features;
        }

        const { contents, tours, products } = this.props;

        return (
            <div className="sidebar sidebar-light sidebar-main sidebar-expand-md align-self-start d-md-none">
                {/* Sidebar mobile toggler */}
                <div className="sidebar-mobile-toggler text-center">
                    <a href="/#" className="sidebar-mobile-main-toggle">
                        <i className="icon-arrow-left8" />
                    </a>
                </div>
                {/* /sidebar mobile toggler */}

                {/* Sidebar content */}
                {booking &&
                <div className="sidebar-content">
                    <div className="card card-sidebar-mobile">
                        {/* Main navigation */}
                        <div className="card-body p-0">
                            <ul className="nav nav-sidebar" data-nav-type="accordion">

                                <li className="nav-item">
                                    <a href="/#" className="nav-link" data-section-id={'home'} onClick={this.handleMenuItemClick}>{i18next.t('Home')}</a>
                                </li>

                                {this.menuItems.map((mentItem, index) => {
                                    if (typeof features[mentItem.name] !== 'undefined' && canDisplayFeature(features[mentItem.name], currentTimePeriod)) {

                                        if (mentItem.name === 'tours' && tours.length === 0) {
                                            return '';
                                        }

                                        if (mentItem.name === 'additional-items' && products.length === 0) {
                                            return '';
                                        }

                                        return (
                                            <li key={index} className="nav-item">
                                                <a href="/#" className="nav-link" data-section-id={mentItem.name} onClick={this.handleMenuItemClick}>{i18next.t(mentItem.label)}</a>
                                            </li>
                                        );
                                    }

                                    return '';
                                })}

                                {
                                    contents.map((content, index) => (
                                        canDisplayContent(content, currentTimePeriod) &&
                                        <li className="nav-item" key={index}>
                                            <a href="/#" className="nav-link" data-section-id={`content-${content.id}`} onClick={this.handleMenuItemClick}>{content.title}</a>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                }

            </div>
        )
    }
}