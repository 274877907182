import {
    FETCH_DAILY_WEATHER_START, FETCH_DAILY_WEATHER_SUCCESS, FETCH_DAILY_WEATHER_ERROR
} from '../../actions/WeatherAction';

const initialState = { data: [], loading: false, errorMessage: '' };

export default function weatherDailyReducer(state = initialState, action) {
    const payload = action.payload;
    switch (action.type) {
        case FETCH_DAILY_WEATHER_START: {
            return { ...initialState, loading: true };
        }
        case FETCH_DAILY_WEATHER_SUCCESS: {
            return { ...initialState, data: payload }
        }
        case FETCH_DAILY_WEATHER_ERROR: {
            return {
                ...initialState, errorMessage: 'Ooops, something went wrong'
            }
        }

        default:
            return state;
    }
}