import React, { Component } from 'react';
import Loading from './common/Loading';
import i18next from "i18next";
import { createReview } from "../actions/ReviewAction";
import Error from "./common/Error";

i18next.t('Leave review');

export default class extends Component {

    constructor(props) {
        super(props);

        this.formData = {};
        //this.state = {showCategoriesRating : false};
        this.state = { showCategoriesRating: true }; // temporary show all categories
    }

    getCategories() {
        return [
            /*
                        { name: 'accuracy', label: i18next.t('Accuracy') },
            */
            { name: 'cleanliness', label: i18next.t('Cleanliness') },
            { name: 'checkin', label: i18next.t('Check-in') },
            { name: 'communication', label: i18next.t('Communication') },
            { name: 'location', label: i18next.t('Location') },
            /*{ name: 'value', label: i18next.t('Value') },*/
        ];
    }

    handleChange = (event) => {
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;

        if (fieldName === 'rating') {
            this.setState({ showCategoriesRating: true });
        }

        if (fieldName === 'rating' || fieldName === 'content') {
            this.formData[fieldName] = fieldValue;
            return;
        }

        if (typeof this.formData.ReviewCategoryRatings == 'undefined') {
            this.formData['ReviewCategoryRatings'] = [];
        }

        const categoryAlreadyExists = this.formData.ReviewCategoryRatings.find(el => el.category === fieldName);

        if (categoryAlreadyExists) {

            this.formData.ReviewCategoryRatings = this.formData.ReviewCategoryRatings.map(element => element.category === fieldName ? {...element, rating: fieldValue} : element);
        }
        else {

            this.formData.ReviewCategoryRatings = [...this.formData.ReviewCategoryRatings, {
                category: fieldName,
                rating: fieldValue
            }];
        }
    };

    handleSubmit(event) {
        event.preventDefault();

        if (!this.formData.rating && !this.formData.content) {
            return;
        }

        this.props.dispatch(createReview(this.props.booking.id, this.formData));

    }

    render() {
        const { booking } = this.props;

        if (!booking) {
            return <Loading />;
        }

        const { loading, successMessage, errorMessage } = this.props.createReview;
        const { showCategoriesRating } = this.state;

        return (
            <>
                <h1 className={'section-heading'}>{i18next.t('Review your stay')}</h1>
                <section id={'review'} className={'review'}>
                    {successMessage &&
                    <div className={'alert alert-success'}>{successMessage}</div>
                    }
                    {!successMessage &&
                    <form onSubmit={this.handleSubmit.bind(this)}>
                        <div className={'row'}>
                            <div className={'col-12'}>
                                <div className={'form-group row'}>
                                    <div className={'col-5'}>
                                        <label className={'center-label'}>{i18next.t('Overall Rating')}</label>
                                    </div>
                                    <div className={'col-7'}>
                                        <div className="rating" onChange={this.handleChange}>
                                            <input type="radio" id="star5" name="rating" value="5" /><label className="full" htmlFor="star5" title={i18next.t('Awesome - 5 stars')} />
                                            <input type="radio" id="star4" name="rating" value="4" /><label className="full" htmlFor="star4" title={i18next.t('Pretty good - 4 stars')} />
                                            <input type="radio" id="star3" name="rating" value="3" /><label className="full" htmlFor="star3" title={i18next.t('Meh - 3 stars')} />
                                            <input type="radio" id="star2" name="rating" value="2" /><label className="full" htmlFor="star2" title={i18next.t('Kinda bad - 2 stars')} />
                                            <input type="radio" id="star1" name="rating" value="1" /><label className="full" htmlFor="star1" title={i18next.t('Sucks big time - 1 star')} />
                                        </div>
                                    </div>
                                    <div className={'clearfix'} />
                                </div>
                                {showCategoriesRating && this.getCategories().map((category, index) => {
                                    return (
                                        <div className={'form-group row'} key={index}>
                                            <div className={'col-5'}>
                                                <label className={'center-label'}>{category.label}</label>
                                            </div>
                                            <div className={'col-7'}>
                                                <div className="rating" onChange={this.handleChange}>
                                                    <input type="radio" id={`star5${category.name}`} name={category.name} value="5" /><label className="full" htmlFor={`star5${category.name}`} title={i18next.t('Awesome - 5 stars')} />
                                                    <input type="radio" id={`star4${category.name}`} name={category.name} value="4" /><label className="full" htmlFor={`star4${category.name}`} title={i18next.t('Pretty good - 4 stars')} />
                                                    <input type="radio" id={`star3${category.name}`} name={category.name} value="3" /><label className="full" htmlFor={`star3${category.name}`} title={i18next.t('Meh - 3 stars')} />
                                                    <input type="radio" id={`star2${category.name}`} name={category.name} value="2" /><label className="full" htmlFor={`star2${category.name}`} title={i18next.t('Kinda bad - 2 stars')} />
                                                    <input type="radio" id={`star1${category.name}`} name={category.name} value="1" /><label className="full" htmlFor={`star1${category.name}`} title={i18next.t('Sucks big time - 1 star')} />
                                                </div>
                                            </div>
                                            <div className={'clearfix'} />
                                        </div>
                                    );
                                })}
                                <div className={'form-group row'}>
                                    <div className="col-12">
                                        <textarea
                                            rows="6"
                                            cols="3"
                                            className="form-control"
                                            placeholder={i18next.t('Enter your comments here')}
                                            name="content"
                                            onChange={this.handleChange.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div className={'form-group row mt-1'}>
                                    <div className="col-form-label col-12">
                                        <div className={'d-flex align-items-end flex-column'}>
                                            <button type={'submit'} className={'btn btn-primary'}>{i18next.t('Send')}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {loading &&
                        <div>{i18next.t('Working')}</div>
                        }
                        {errorMessage &&
                        <Error message={errorMessage} />
                        }
                    </form>
                    }
                </section>
            </>
        );
    }
}