import axios from "axios";

export const FETCH_CONTENTS_START = 'FETCH_CONTENTS_START';
export const FETCH_CONTENTS_SUCCESS = 'FETCH_CONTENTS_SUCCESS';
export const FETCH_CONTENTS_ERROR = 'FETCH_CONTENTS_ERROR';


export function fetchContents(bookingId) {
    return function (dispatch) {
        dispatch({ type: FETCH_CONTENTS_START });
        const request = axios.get(`/bookings/${bookingId}/contents`);
        request.then((res) => {
            dispatch({ payload: res.data, type: FETCH_CONTENTS_SUCCESS });
        }).catch((e) => {
            dispatch({ type: FETCH_CONTENTS_ERROR })
        });
    }
}