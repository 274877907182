export const PERIOD_BEFORE_ARRIVAL = 2;
export const PERIOD_DURING_STAY = 4;
export const PERIOD_AFTER_STAY = 8;

export function currentPeriod(booking) {
    const arrivalTime = new Date(`${booking.arrivalDate}T00:00`).getTime();
    const departureTime = new Date(`${booking.departureDate}T23:59`).getTime();
    const currentTime = new Date().getTime();

    if (currentTime < arrivalTime) {
        return PERIOD_BEFORE_ARRIVAL;
    }

    if (currentTime > arrivalTime && currentTime < departureTime) {
        return PERIOD_DURING_STAY;
    }

    return PERIOD_AFTER_STAY;
}