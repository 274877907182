import React, { Component } from 'react';
import i18next from "i18next";
import CreateMessageView from "./CreateMessageView";
import MessageListView from "./MessageListView";

export default class MessageView extends Component {

    render() {

        return (
            <>
                <h1 className={'section-heading'}>{i18next.t('Message')}</h1>
                <section id={'message'}>
                    <MessageListView />
                    <CreateMessageView />
                </section>
            </>
        );
    }
}