import React, { Component } from 'react';
import Loading from "./common/Loading";
import { currentPeriod } from "../utils/DisplayTimePeriodUtils";
import { BOOKING_ID, session } from "../utils/Session";

export default class extends Component {

    componentDidMount() {
        this.props.fetchContents(session.getItem(BOOKING_ID));
    }

    render() {
        const { contents, loading } = this.props.contentList;
        const { booking } = this.props.currentBooking;

        if (loading || !booking) {
            return <Loading />;
        }

        const currentTimePeriod = currentPeriod(booking);

        return (
            <>
                {contents.map((content, index) => (
                    content.active && content.displayTimePeriods.includes(currentTimePeriod) &&
                    <div style={{'marginTop':'50px'}} key={index}>
                        <h1 className={'section-heading'}>{content.title}</h1>
                        <section id={`content-${content.id}`} key={index}>
                            <p className={'content-body'} dangerouslySetInnerHTML={{ __html: content.content }} />
                        </section>
                    </div>
                ))}

            </>
        );
    }
}
