import { connect } from 'react-redux';
import WeatherView from '../views/WeatherView';
import { fetchCurrentlyWeather, fetchDailyWeather } from "../actions/WeatherAction";

const mapStateToProps = (state) => {
    return {
        booking: state.currentBooking.booking,
        weatherDaily: state.weatherDaily,
        weatherCurrently: state.weatherCurrently,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCurrentlyWeather: (bookingId) => dispatch(fetchCurrentlyWeather(bookingId)),
        fetchDailyWeather: (bookingId) => dispatch(fetchDailyWeather(bookingId)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(WeatherView);
