import i18next from "i18next";
import {
    FETCH_MESSAGES_START, FETCH_MESSAGES_SUCCESS, FETCH_MESSAGES_ERROR,
} from '../../actions/MessageAction';

const initialState = {
    messages: [],
    errorMessage: '',
    loading: false,
};

export default function messageListReducer(state = initialState, action) {
    const payload = action.payload;
    switch (action.type) {
        case FETCH_MESSAGES_START: {
            return { ...state, loading: true };
        }
        case FETCH_MESSAGES_SUCCESS: {
            return { ...initialState, messages: payload }
        }
        case FETCH_MESSAGES_ERROR: {
            return { ...initialState, errorMessage: i18next.t('Ooops, something went wrong') }
        }
        default:
            return state;
    }
}