import axios from "axios";

export const FETCH_TOURS_START = 'FETCH_TOURS_START';
export const FETCH_TOURS_SUCCESS = 'FETCH_TOURS_SUCCESS';
export const FETCH_TOURS_ERROR = 'FETCH_TOURS_ERROR';


export function fetchTours(bookingId) {
    return function (dispatch) {
        dispatch({ type: FETCH_TOURS_START });
        const request = axios.get(`/bookings/${bookingId}/tours`);
        request.then((res) => {
            dispatch({ payload: res.data, type: FETCH_TOURS_SUCCESS });
        }).catch((e) => {
            dispatch({ type: FETCH_TOURS_ERROR })
        });
    }
}