import {
    CREATE_REVIEW_START, CREATE_REVIEW_SUCCESS, CREATE_REVIEW_ERROR,
} from '../actions/ReviewAction';

const initialState = {
    validationMessages: [],
    successMessage : '',
    errorMessage: '',
    loading: false,
};

export default function createReviewReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_REVIEW_START: {
            return { ...initialState, loading: true };
        }
        case CREATE_REVIEW_SUCCESS: {
            return { ...initialState, successMessage: 'Successfully submitted. Thanks for your feedback' }
        }
        case CREATE_REVIEW_ERROR: {
            return { ...initialState, errorMessage: 'Ooops, something went wrong' }
        }
        default:
            return state;
    }
}