import React, { Component } from 'react';
import Loading from "./common/Loading";
import { fetchTours } from "../actions/TourAction";
import i18next from "i18next";
import { BOOKING_ID, session } from "../utils/Session";

export default class extends Component {

    componentDidMount() {
        const bookingId = session.getItem(BOOKING_ID);
        this.props.dispatch(fetchTours(bookingId));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.tourList.tours.length !== this.props.tourList.tours.length) {

            this.slider = window.jQuery('.tour-list').lightSlider({
                item: 1,
                pager: false,
                speed:500,
                auto:true,
                loop:true,
                pause: 7000,
                prevHtml: '<i class="fas fa-arrow-left" />',
                nextHtml: '<i class="fas fa-arrow-right" />',
            });
        }
    }

    componentWillUnmount() {
        if (this.slider) {
            this.slider.destroy();
        }
    }

    render() {
        const { tours, loading } = this.props.tourList;

        if (loading) {
            return <Loading />;
        }

        if (!tours.length) {
            return '';
        }

        return (
            <>
            <h1 className={'section-heading'}>{i18next.t('Book an experience')}</h1>
            <section id={'tours'}>
                <div className={'tours'}>
                    <ul className="tour-list">
                        {tours.map((tour, index) => {
                            const picture = tour.pictures.length ? tour.pictures[0] : null;
                            return (
                                <li className="item" key={index}>
                                    <a href={tour.url} target="_blank" rel="noopener noreferrer">
                                        <div className="card">
                                            <div className={'row'}>
                                                {picture && <div className={'col-12 col-sm-4 card-body'}>
                                                    <img src={picture.url.replace('[format_id]', 68)} alt="" />
                                                </div>}
                                                <div className={'col-12 col-sm-8'}>
                                                    <div className="card-header">
                                                        <h5 className="card-title font-weight-semibold">{tour.title}</h5>
                                                    </div>
                                                    <div className={'card-body'}>
                                                        <p>{tour.abstract}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </section>
            </>
        );
    }
}
