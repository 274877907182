import { connect } from 'react-redux';
import OnlineCheckInView from '../views/OnlineCheckInView';

const mapStateToProps = (state) => {
    return {
        currentBooking: state.currentBooking,
    };
};

export default connect(mapStateToProps, {})(OnlineCheckInView);
