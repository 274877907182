import { connect } from 'react-redux';
import HeaderView from '../views/HeaderView';

const mapStateToProps = (state) => {
    return {
        currentBooking: state.currentBooking,
    };
};

export default connect(mapStateToProps, {})(HeaderView);
