import { connect } from 'react-redux';
import AppView from '../views/AppView';
import { fetchBooking } from "../actions/BookingAction";

const mapStateToProps = (state) => {
    return {
        currentBooking: state.currentBooking,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchBooking: (id) => dispatch(fetchBooking(id)),
        dispatch
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppView);