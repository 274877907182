import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchMessages } from "../../actions/MessageAction";
import { BOOKING_ID, session } from "../../utils/Session";


const TYPE_INBOX = 1;
//const TYPE_OUTBOX = 2;

export class MessageListView extends Component {

    componentDidMount() {
        this.props.dispatch(fetchMessages(session.getItem(BOOKING_ID)));
    }

    componentDidUpdate(){
        const messageListElement = window.jQuery('.message-list')[0];
        messageListElement.scrollTop = messageListElement.scrollHeight;
    }

    render() {
        const { messages } = this.props.messageList;

        return (
            <ul className={'message-list'}>
                {messages.map((message, index) => {

                    return (
                        <li key={index}>
                            <div className={`chat-bubble ${message.type === TYPE_INBOX ? 'chat-bubble-left' : 'chat-bubble-right'}`}>
                                <span dangerouslySetInnerHTML={{__html : message.htmlMessage}} />
                            </div>
                            <div className={'clearfix'} />
                        </li>
                    );
                })
                }
            </ul>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        messageList: state.messageList,
    };
};

export default connect(mapStateToProps)(MessageListView);
