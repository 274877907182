import {
    CREATE_ORDER_START, CREATE_ORDER_SUCCESS, CREATE_ORDER_ERROR,DISABLE_ORDER_BUTTON, ENABLE_ORDER_BUTTON
} from '../../actions/CrossSellingAction';
import i18next from "i18next";

const initialState = {
    errorMessage: '',
    successMessage: '',
    loading: false,
    disableOrderButton : true
};

export default function orderReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_ORDER_START: {
            return { ...initialState, loading: true };
        }
        case CREATE_ORDER_SUCCESS: {
            return { ...initialState, successMessage: i18next.t('Order was successful') }
        }
        case CREATE_ORDER_ERROR: {
            return { ...initialState, errorMessage: i18next.t('Ooops, something went wrong'), disableOrderButton : false }
        }
        case DISABLE_ORDER_BUTTON: {
            return { ...initialState, disableOrderButton: true }
        }
        case ENABLE_ORDER_BUTTON: {
            return { ...initialState, disableOrderButton: false }
        }
        default:
            return state;
    }
}