import { connect } from 'react-redux';
import ReviewView from '../views/ReviewView';

const mapStateToProps = (state) => {
    return {
        booking: state.currentBooking.booking,
        createReview: state.createReview,
    };
};

export default connect(mapStateToProps)(ReviewView);
